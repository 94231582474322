import { AuthProvider } from "@rimo/frontend/types/auth";

import { PlanType } from "./plan";
import { Team } from "./team";
import { getAuth } from "firebase/auth";

export type Support =
  | "video"
  | "dictionary"
  | "team_duration_limit" // 部署ごとの利用時間制限(法人全体の利用時間制限とは異なる)
  | "super_admin"
  | "advanced_summary"
  | "stop_invitation_mail"
  | "stop_category_mail"
  | "stop_category_mail_exclude_admin"
  | "disable_upload_limit"
  | "salesforce"
  | "ai_template";

// model
export type OrganizationSecurity = {
  accepted_ips?: string[];
  accepted_provider?: AuthProvider;
  link_share_disabled?: boolean;
  use_session_login?: boolean;
  invitable_domains?: string[];
  sharable_domains?: string[];
  use_azure_openai?: boolean;
};

export type OrganizationInfo = OrganizationSecurity & {
  id: string;
  root_team_id: string;
  created_at: Date;
  updated_at: Date;
  plan_type: PlanType;
  upload_forbidden?: boolean;
  default_locale?: string;
  locales?: string[];
  disabled_supports?: Support[];
  enabled_supports?: Support[];
  saml_provider_id?: string;
  default_annotation_context_id?: string;
  disabled_data_availability_default?: boolean; // 企業全体でデータ利用可否を無効にするかどうか
  hide_summary_setting: boolean;
  hide_dictionary_setting: boolean;
  hide_user_personal_channels: boolean;
  use_claude?: boolean; // LLMにてClaudeを利用するかどうか
  llm_model_display_name?: string; // その法人が使用するLLMモデル

  hard_restricted?: boolean;
  disable_organization_channel?: boolean;

  extract_speakers_enabled?: boolean;
  diarization_enabled?: boolean;

  recommend_integrated_google?: boolean;
  recommend_integrated_microsoft?: boolean;

  /**
   * 連携済みSlackチャンネル名("#" 等付与済み)
   * 未連携時は空文字
   */
  slack_channel_name: string;
  /** Slack連携設定値(新しいリリースのアナウンス) */
  slack_notify_announce_new_release: boolean;
  /** Slack連携設定値() */
  slack_notify_note_translated: boolean;
  /** Slack連携設定値() */
  slack_notify_steps_starter: boolean;
  slack_mention_type: string;

  // 文字起こしエンジンのデフォルト値
  default_engine?: string;

  domains: {
    id: string;
    created_at: string;
    updated_at: string;
    is_exclusive: boolean;
  }[];
};
export type Organization = Omit<Team, "children"> & { org_info: OrganizationInfo };

export type Prepaid = {
  amount_remaining: number;
  duration_sec_remaining: number;
  end_at: string;
  start_at: string;
};

// const
export const TeamRole = {
  SuperAdmin: "super_admin",
  Admin: "admin",
  Member: "member",
  Invitation: "invitation",
} as const;
export type TeamRole = (typeof TeamRole)[keyof typeof TeamRole];

export const TeamLabel: { [key in TeamRole]: string } = {
  super_admin: "特権管理者",
  admin: "管理者",
  member: "メンバー",
  invitation: "招待中",
};
export type PayPlanCode = "prepaid_plan" | "subscription_plan" | "pay_plan";

export interface PreferenceRole {
  hide_annotation_context_setting: boolean;
  hide_dictionary_setting: boolean;
  hide_import_setting: boolean;
  hide_locale_setting: boolean;
  note_summary_permission: number;
}

export const getTeamRoleString = (role: string) => {
  switch (role) {
    case TeamRole.SuperAdmin:
      return TeamLabel["super_admin"];
    case TeamRole.Admin:
      return TeamLabel["admin"];
    case TeamRole.Member:
      return TeamLabel["member"];
    case TeamRole.Invitation:
      return TeamLabel["invitation"];
    default:
      return "不明";
  }
};

export function isTeamRole(role: string): role is TeamRole {
  return (Object.values(TeamRole) as string[]).includes(role);
}

export const isAdminTeamRole = (role: string | null | undefined) => {
  return role === TeamRole.SuperAdmin || role === TeamRole.Admin;
};

export const isPersonalOrganization = (org: Organization, uid?: string | undefined): boolean => {
  const user = getAuth().currentUser;
  return org.id === `user_${uid ?? user?.uid ?? ""}`;
};

export const isPersonalOrganizationId = (org: Organization): boolean => {
  return org.id.startsWith(`user_`);
};

export const isTier1 = (orgId?: string) => {
  return orgId === "wIb1ctbsbsFU29hQWeHn"; // 大成建設株式会社建築本部プロジェクト・マネジメント部
};
